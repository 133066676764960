// 该文件为系统默认配置，请勿修改！！！

import type { RecursiveRequired, Settings } from '#/global'

const globalSettingsDefault: RecursiveRequired<Settings.all> = {
  app: {
    theme: 'default',
    colorScheme: 'dark',
    elementSize: 'default',
    defaultLang: 'zh-cn',
    enablePermission: false,
    enableProgress: false,
    enableDynamicTitle: true,
    storagePrefix: 'wsdy_',
    enableWatermark: false,
    enableErrorLog: false,
    routeBaseOn: 'frontend',
  },
  home: {
    enable: true,
    title: '主页',
  },
  layout: {
    widthMode: 'adaption',
    enableMobileAdaptation: false,
  },
  menu: {
    baseOn: 'frontend',
    menuMode: 'single',
    menuFillStyle: '',
    menuActiveStyle: '',
    switchMainMenuAndPageJump: false,
    subMenuUniqueOpened: false,
    subMenuOnlyOneHide: false,
    subMenuCollapse: false,
    enableSubMenuCollapseButton: false,
    enableHotkeys: false,
  },
  topbar: {
    mode: 'static',
    switchTabbarAndToolbar: false,
  },
  tabbar: {
    enable: true,
    style: 'fashion',
    enableIcon: false,
    mergeTabsBy: '',
    enableHotkeys: false,
  },
  toolbar: {
    enableNotification: false,
    enableI18n: false,
    enableFullscreen: false,
    enablePageReload: false,
    enableColorScheme: false,
    enableAppSetting: false,
  },
  breadcrumb: {
    enable: true,
    style: '',
    enableMainMenu: false,
  },
  mainPage: {
    enableHotkeys: true,
    iframeCacheMax: 3,
  },
  navSearch: {
    enable: false,
    enableHotkeys: false,
  },
  copyright: {
    enable: true,
    dates: '2022-2023',
    company: '途云科技',
    website: 'https://www.miit.gov.cn/',
    beian: '渝ICP备20008438号-1',
  },
}

export default globalSettingsDefault
